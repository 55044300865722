<template>
  <div class="main">
    <header-t />
    <nav2 />
    <div class="cont">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item v-if="type === 'notice'"
          >公告</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="type === 'news'">资讯</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="item mar-bottom">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-card shadow="nerve" class="card">
              <!-- <el-tabs v-model="activeName" @tab-click="handleClick"> -->

              <div class="art-top" v-for="item in rows" :key="item.rows">
                <div class="art-intro" @click="goDetail(item.noticeId)">
                  <div class="art-intro-left">
                    {{ item.noticeTitle }}
                  </div>
                  <div class="art-intrp-right">
                    <div class="icon">
                      <i class="el-icon-time"></i>
                      <span class="icontime">{{ item.createTime }}</span>
                    </div>
                    <!-- <div class="icon" style="color:#666666"><i class="el-icon-collection-tag"></i>
                            <span class="icontime">开课通知</span>
                          </div> -->
                  </div>
                </div>
              </div>
              <!-- </el-tabs> -->
              <div class="pagination">
                <Pagination
                  :total="search.total"
                  :page.sync="search.page"
                  :limit.sync="search.size"
                  @pagination="getDataList"
                />
              </div>
            </el-card>
          </el-col>
          <!-- <el-col :span="6">
            <el-card shadow="nerve" class="card">
              <div class="panel">
                <div class="tit">
                  <h3>热门焦点</h3>
                </div>
                <div class="body">
                  <ul class="dynamic">
                    <li>2022年现场岗位八大员第五期新取证培训通知</li>
                  </ul>
                </div>
              </div>
            </el-card>
          </el-col> -->
        </el-row>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderT from '@/components/HeaderT'
import Nav2 from '@/components/Nav2'
import Footer from '@/components/Footer'
import Pagination from '@/components/Pagination'
import { getNotice } from '@/api/index'
export default {
  name: 'noticeList',
  components: {
    HeaderT,
    Nav2,
    Footer,
    Pagination
  },
  data() {
    return {
      info: {},
      noticeId: null,
      activeName: 'first',
      search: {
        page: 1,
        size: 10,
        total: 0 //分页
      },
      // noticeType: 1,
      rows: [],
      type: '' // 类型
    }
  },
  created() {
    console.log(this.$route.query.type)
    if (this.$route.query.type) {
      this.type = this.$route.query.type
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    getDataList() {
      getNotice(Object.assign(this.search, { noticeType: 1 })).then((res) => {
        console.log(res)
        if (res.data.code === 0) {
          this.rows = res.data.data.rows
          this.search.total = res.data.data.total
          // this.$message({
          //   type:'success',
          //   message:res.data.message
          // })
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    goDetail(id) {
      this.$router.push({
        path: '/article',
        query: {
          noticeId: id,
          type: 'notice'
        }
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.main {
  height: 100%;
  background-color: #f5f5f5;
  .cont {
    width: 1170px;
    margin: 20px auto;
    .item {
      margin-top: 20px;
      .card {
        /* .art-top{
          border-top: 1px solid #ddd;
          margin-top: 1px; */
        .art-intro {
          position: relative;
          text-align: left;
          display: flex;
          justify-content: space-around;
          /* margin-top: 30px; */
          /* width: 825px; */
          height: 55px;
          line-height: 55px;
          /* border-top: 1px solid #ddd; */
          border-bottom: 1px solid #ddd;
          font-size: 14px;
          .art-intro-left {
            width: 65%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            color: #333;
            text-decoration: none;
            a {
              cursor: pointer;
              color: #333;
              text-decoration: none;
              outline: none;
              margin-left: 5px;
            }
          }
          .art-intro-left:hover {
            color: #b7090a;
          }
          .art-intrp-right {
            width: 50%;
            /* display: flex;
            justify-content: space-around; */
            .icon {
              width: 45%;
              font-size: 16px;
              color: #999999;
              float: right;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              .icontime {
                font-size: 14px;
                margin-left: 10px;
              }
            }
          }
        }
        /* } */
        .pagination {
          margin-top: 30px;
        }
      }
      .panel {
        .tit {
          text-align: left;
          line-height: 30px;
          border-bottom: 1px solid #f5f5f5;
        }
        .body {
          padding: 15px 0;
          .media-left {
            display: table-cell;
            vertical-align: top;
            padding-right: 15px;
            .avatar-md {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              margin: 5px;
            }
          }
          .media-member {
            display: table-cell;
            vertical-align: top;
            .avatar-md {
              float: left;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              margin: 5px;
            }
          }
          .dynamic {
            margin-top: 5px;
            li {
              padding-bottom: 15px;
              text-align: left;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: normal;
              color: #666;
            }
          }
          .media-right {
            display: table-cell;
            vertical-align: top;
            font-size: 14px;
            .title {
              margin: 10px 0 10px 0;
              text-align: left;
            }
          }
        }
      }
    }
    .mar-bottom {
      margin-bottom: 20px;
    }
  }
}
</style>